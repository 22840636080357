import GameIcon1 from "assets/icon/game-icon1.png"
import GameIcon2 from "assets/icon/game-icon2.png"
import GameIcon3 from "assets/icon/game-icon3.png"
import { useState } from "react"

interface ItemType {
  id: number
  title: string
  desc: string
  className: string
  url: string
  icon: string
}

let cardItemList: ItemType[] = [
  {
    id: 1,
    title: `DK MOBILE : GENESIS`,
    desc: `DK MOBILE : GENESIS is a realtime MMORPG, <br/>
    the first on-chain game of ITSBLOC`,
    className: "game1",
    url: "https://play.google.com/store/apps/details?id=com.ntrance.dkorigincoinglobal",
    icon: GameIcon1
  },
  {
    id: 3,
    title: `Call of Chaos`,
    desc: `Call of Chaos is the second MMORPG game with  high-quality Quarter view graphic shifting from full 3D`,
    className: "game3",
    url: "",
    icon: GameIcon3
  },
]

export const Gameverse = () => {
  const [clickItem, setClickItem] = useState(1)
  const [list, setList] = useState(cardItemList)
  const swapItem = (currentId: number, targetId: number) => {
    const currentItem = cardItemList.find(i => i.id === currentId) as ItemType
    const targetItem = cardItemList.find(i => i.id === targetId) as ItemType
    const arr = list.map(i => {
      if (i.id === currentId) return targetItem
      if (i.id === targetId) return currentItem
      return i
    })
    setList(arr)

  }

  return <section className="gameverse-container">
    <h3 className="has-ani mb24">
      ITSBLOC Games
    </h3>

    <h4 className="has-ani mb52">
      Join the ITSBLOC Game<br />
      Our games and blockchain services will provide<br />
      users maximum fun to an evolving world
    </h4>

    <div className="game-card-container">
      <div className="left-card">
        <div className="active-bg"></div>
        <div className="desc">
          <div className="top">
            <h3 className="game-title mb15">DK Mobile : GENESIS</h3>
            <h4 className="game-desc">DK Mobile : GENESIS is a realtime MMORPG, <br />
              the first on-chain game of ITSBLOC</h4>
          </div>


          <div className="bottom">
            <div className="d-flex y-center">
              <div className="button-container app-down mr20">
                <a href="https://play.google.com/store/apps/details?id=com.ntrance.dkorigincoinglobal" target="_blank">
                  <p className="button-text">
                    App Download
                  </p>
                </a>
              </div>

              <h4 className="os-text">
                Only for Android
              </h4>

            </div>

            <div className="">
              <img src={GameIcon1} alt={"game1"} />
            </div>
          </div>
        </div>
      </div>
      
      <div className="right-card-container">
        <div className="right-card game2">
          <div className="active-bg"></div>
          <div className="desc">
            <div className="top">
              <h3 className="game-title mb15">Call of Chaos</h3>
              <h4 className="game-desc">Call of Chaos is the second MMORPG game with  high-quality Quarter view graphic shifting from full 3D </h4>
            </div>


            <div className="bottom">
              <div className="d-flex y-center">
                <div className="button-container app-down no-open mr20">
                  <p className="button-text">
                    App Download
                  </p>

                  <p className="button-text no-open-text">
                    Comming Soon
                  </p>
                </div>
                <h4 className="os-text">
                  Only for Android
                </h4>
              </div>

              <img src={GameIcon3} alt={"game3"} />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="over-rap-card">
      {list.map((i, index) => {
        const active = i.id === clickItem
        const beforeClickItem = clickItem
        return <div
          key={"item" + index + i.id}
          className={`card ${active && i.className + " active"} ${"order" + index}`}
          onClick={() => {
            if (!active) {
              swapItem(beforeClickItem, i.id)
              setClickItem(i.id)
            }
          }}
        >
          {active && <div className="top">
            <h3 className="game-title mb15" dangerouslySetInnerHTML={{ __html: i.title }}></h3>
            <h4 className="game-desc" dangerouslySetInnerHTML={{ __html: i.desc }}></h4>
          </div>}


          <div className="bottom">
            {active ? <>
              <div className="d-flex y-center">
                <div className="button-container app-down mr20">
                  {i.url ? <a href={i.url} target={"_blank"}>
                    <p className="button-text">
                      App Download
                    </p>
                  </a> :
                    <p className="button-text">
                      Comming Soon
                    </p>
                  }

                </div>

                <h4 className="os-text">
                  Only for Android
                </h4>

              </div>

              <div className="">
                <img src={i.icon} alt={"game1"} />
              </div>

            </> : <div className="d-flex direction-y"><h3 className="game-title mb15" dangerouslySetInnerHTML={{ __html: i.title }}></h3>
              <h4 className="game-desc" dangerouslySetInnerHTML={{ __html: i.desc }}></h4></div>}
          </div>
        </div>
      })}
    </div>

  </section>
}
