import { useState } from "react"

const qlist = [
  {
    title: `What is ITSBLOC wallet?`,
    desc: `ITSBLOC Wallet provides ITSBLOC token, ITSBLOC Credit, game tokens, and other units. With ITSBLOC tokens, various digital ownership of game items, integration, operation, and management of all rights are allowed within the system.

All alliance games included in the ITSBLOC platform can issue tokens in different forms for each game. 

It works positively for producers and users while promoting a fair economy.`
  },

  {
    title: `What is a ITSBLOC token ?`,
    desc: `ITSBLOC token is the Governance Token for the ITSBLOC ecosystem. 
    
    $ITSB is used for staking and trading between game tokens. 
    
    In the ITSBLOC platform, containing wallet, swap, DEX, ITSBLOC is traded as private chain and used as a public blockchain when connected to an external wallet.

Therefore, the users who want to use the platform and play the games boarded on the platform should buy ITSBLOC from CEX in which it is listed or exchange it from Matic or USDC on the platform.

In addition, voting rights are attached to the ITSBLOC token. For example, the participants of ITSBLOC use ITSBLOC token for community treasures including staking rewards, new product development proposals, etc. and new features in the product include NFT marketplace, gameplay, artwork, and more Staking for benefits (applying to all games) players are given game rewards.

As players change ITSBLOC token to game token for staking, and receive game benefits, game characters will have a better gaming experience, growing faster and receiving more rewards. Players who stake ITSBLOC token are entitled to a reward (usually by staking) paid in ITSBLOC. Staking rewards are in-game privileges.

Users can play games for free on the ITSBLOC platform. However, one of the special benefits of using the game is staking. The staking is the biggest benefit for users to play the game, and it is only allowed by ITSBLOC token, so users have to have ITSBLOC token in order to stake their value.

The release time of staking varies from game to game, and we are deciding for a safe and healthy ecosystem. The staking policy is determined in consideration of technology and will be updated in the future depending on the service-related situation.
`},
  {
    title: "What is Staking?",
    desc: `Users can play games for free on the ITSBLOC platform. 
    
    However, one of the special benefits of using the game is staking. 
    
    The staking is the biggest benefit for users to play the game. 
    
    On this basis, ITSBLOC's staking system works in such a way that players are given incentives to help them play the actual game. 
    
    The timing of the staking release varies from game to game, and we are making a decision for a safe and healthy ecosystem. 
    
    The staking policy is determined in consideration of technology and will be updated in the future depending on the service-related situation.
    
The timing of the staking release varies from game to game, and we are making a decision for a safe and healthy ecosystem. 

The staking policy is determined in consideration of technology and will be updated in the future depending on the service-related situation.
`},
  {
    title: "I lost my account password.",
    desc: `
    If the password is entered incorrectly 5 times, the 
    wallet owner's password will be reset. And you can 
    reset your password after 24 hours. If you any further 
    questions, please contact contact@itsbloc.io for help.`
  },
  {
    title: `Where can I buy ITSB tokens?`, 
    desc: `Domestic users can purchase polygon Matic on 
    Bithumb or Upbit and swapping it into ITSB tokens on 
    overseas exchanges like MEXC or Gate.io.
    
    Travel rules are applied in Korea, so if you send more than 1 million won, please check that it may be a problem and deposit and withdraw it.` },

]

const NewlineText = (text: string) => {
  const newText = text.split('\n').map(str => <p key={Math.random()}>{str}</p>);

  return newText;
}


export const Question = () => {
  const [questions] = useState(qlist)
  const [activeQuestion, setActiveQuestion] = useState(-1)

  return <section className="togather-container" id="faq">
    <h3 className="has-ani mb24">
      Any Questions?
    </h3>

    <h4 className="has-ani mb64">
      These are Frequently asked questions.<br />
      Is there Anyone have any questions?
    </h4>

    <div className="question-wrap">
      {questions.map((i, index) => {
        return <div key={index + "Question"} className={`question-container ${activeQuestion === index && "active"}`}
          onClick={() => activeQuestion === index ? setActiveQuestion(-1) : setActiveQuestion(index)}>
          <div className="question-title">
            <p>
              {i.title}
            </p>
          </div>
          <div className="question-desc">
            {NewlineText(i.desc)}
          </div>
        </div>
      })}


    </div>

  </section>
}
