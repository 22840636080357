import Char1 from "assets/img/char1.png";
import Char1m from "assets/img/char1-m.png";
import Char2 from "assets/img/char2.png";
import Char2m from "assets/img/char2-m.png";
import LogoBox from "assets/img/logo-box.png";
import ArrowDown from "assets/icon/arr-down.svg"
import Token1 from "assets/img/token1.svg"
import Token2 from "assets/img/token2.svg"
import Token3 from "assets/img/token3.svg"
import Token4 from "assets/img/token4.svg"
import Token5 from "assets/img/token5.svg"
import Token6 from "assets/img/token6.svg"
import Token7 from "assets/img/token7.svg"
import { useEffect, useState } from "react";
import Axios from "axios";
import Up from "assets/icon/arr-up.svg"

interface ItsblocType {
  usd: number,
  usd_24h_change
  : number
}

export const JoinUs = () => {
  const [buttonActive, setButtonActive] = useState(false)
  const [price, setPrice] = useState<ItsblocType>({
    usd: 0,
    usd_24h_change: 0
  })

  useEffect(() => {
    Axios.get("https://api.coingecko.com/api/v3/simple/price?ids=itsbloc&vs_currencies=usd&include_24hr_change=true").then(({ data }) => {
      setPrice(data.itsbloc)
    })
  }, [])

  return <section className="join-us-container">
    <div className="inner">
      <div className="left-box">
        <h3 className="has-ani mb24 tl">
          Join us on<br />
          Our Journey.
        </h3>

        <h4 className="has-ani mb40 tl">
          Join the fast-growing token economy<br />
          and build a stable foundation
        </h4>

        <div className={`appdown-button-container ${buttonActive ? "active" : ""}`}>
          <div className={`button-container app-down only-black mr20 ${buttonActive ? "active" : ""}`}>
            <div className="d-flex x-center pointer" onClick={() => setButtonActive(!buttonActive)}>
              <p className="button-text mr7">
                App Download
              </p>
              <img src={ArrowDown} alt="arr-down" className={buttonActive ? "down-arr" : ""} />
            </div>

            {buttonActive && <ul className="download-active">
              <li className="mb18">
                <div className="download-container">
                  <img src={LogoBox} alt={"char1"} className="logo-box" />
                  <div>
                    <p className="target-text">ITSBLOC for IOS</p>
                    <p className="desc">Join the fast-growing<br />
                      token economy </p>
                  </div>
                </div>
                <div className="down-icon">
                  <a href="https://apps.apple.com/kr/app/itsbloc/id1632866346" target="_blank">
                    Download
                  </a>
                </div>
              </li>
              <li>
                <div className="download-container">
                  <img src={LogoBox} alt={"char1"} className="logo-box" />
                  <div>
                    <p className="target-text">ITSBLOC for AOS</p>
                    <p className="desc">Join the fast-growing<br />
                      token economy </p>
                  </div>
                </div>
                <div className="down-icon">
                  <a href="https://play.google.com/store/apps/details?id=io.itsbloc.wallet&hl=ko" target="_blank">
                    Download
                  </a>
                </div>
              </li>
            </ul>}
          </div>

          {buttonActive ? <></> : <h4 className="os-text">
            All - Platform Provide.
          </h4>}

        </div>
      </div>

      <div className="d-flex y-center direction-y">
        <div className="mobile">
          <div className="char-box mb12">
            <img src={Char1m} alt={"char1"} className="char1" />
            <img src={Char2m} alt={"char2"} className="char2" />
          </div>

          <div className="row-box type2 mobile">
            <div className="token-box">
              <ul className="mb3">
                <li><img src={Token1} /></li>
                <li><img src={Token2} /></li>
                <li><img src={Token3} /></li>
                <li><img src={Token4} /></li>
                <li><img src={Token5} /></li>
                <li><img src={Token6} /></li>
                <li><img src={Token7} /></li>
                <li><img src={Token1} /></li>
                <li><img src={Token2} /></li>
                <li><img src={Token3} /></li>
                <li><img src={Token4} /></li>
                <li><img src={Token5} /></li>
                <li><img src={Token6} /></li>
                <li><img src={Token7} /></li>
              </ul>
              <h4 className="token-text">Token in<br /> ITSBLOC app</h4>
            </div>
          </div>
        </div>
        <div className="char-box mb12 web">
          <img src={Char1} alt={"char1"} className="char1" />
          <img src={Char2} alt={"char2"} className="char2" />
        </div>
        <div className="row-box type1 mb12">
          <div className="mr12">
            <img src={LogoBox} alt={"char1"} className="logo-box" />
          </div>
          <div className="row-box type2">
            <div className="token-box">
              <ul className="mt10 mb10">
                <li><img src={Token1} /></li>
                <li><img src={Token2} /></li>
                <li><img src={Token3} /></li>
                <li><img src={Token4} /></li>
                <li><img src={Token5} /></li>
                <li><img src={Token6} /></li>
                <li><img src={Token7} /></li>
                <li><img src={Token1} /></li>
                <li><img src={Token2} /></li>
                <li><img src={Token3} /></li>
                <li><img src={Token4} /></li>
                <li><img src={Token5} /></li>
                <li><img src={Token6} /></li>
                <li><img src={Token7} /></li>
              </ul>
              <h4 className="token-text">Token in ITSBLOC app</h4>
            </div>
          </div>
        </div>
        <div className="balance-box">
          <h5 className="balance-title">ITBLOC (ITSB)</h5>
          <h4 className="balance-text">${price.usd.toFixed(4)}
            <span className={`up-down-text ${price.usd_24h_change
              > 0 ? "up" : "down"}`}>
              {price.usd_24h_change
                > 0 ? <img src={Up} /> : <img className="down-arr" src={Up} />}
              {Math.abs(price.usd_24h_change
              ).toFixed(2)}</span>
          </h4>
        </div>
      </div>
    </div>
  </section>
}
