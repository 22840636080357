import { useEffect } from 'react';

export function intersectionInit() {
  const config = {
    rootMargin: '0px 50px 70px 100px',
    threshold: 0.4,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        entry.target.classList.add('on-view--true');
      }
    });
  }, config);

  useEffect(() => {
    setTimeout(() => {
      const section = document.querySelectorAll('section');
      section.forEach((image) => {
        observer.observe(image);
      });
    }, 1000);

    return observer.disconnect();
  }, []);
}
