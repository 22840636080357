
import Axios from 'api';
import { Community } from 'components/Community';
import { Gameverse } from 'components/Gameverse';
import { JoinUs } from 'components/JoinUs';
import { MainVisual } from 'components/MainVisual';
import Modal from 'components/Modal';
import Modal2 from 'components/Modal2';
import { Question } from 'components/Question';
import { SendEmail } from 'components/SendEmail';
import { Together } from 'components/Together';
import { VideoSection } from 'components/VideoSection';
import { useCallback, useEffect, useState } from 'react';

const Main = () => {
  const [isOpenModal, setOpenModal] = useState(false);

  const onClickToggleModal = useCallback(() => {
    setOpenModal(!isOpenModal);
  }, [isOpenModal]);
  
  useEffect(() => {
    setOpenModal(true);
  },[]);
 
  
  return (
    <>
    {(isOpenModal) &&
      <div className="modal-container">
        {isOpenModal && (
            <Modal onClickToggleModal={onClickToggleModal}>
              ◆ DK Mobile : Genesis Season 3 end<br/><br/>

              1. Season End Date: Wednesday, August 30, 2023 05:00 UTC<br/>
              2. Account Initialization: After the season ends, all data such as characters, transformations, dolls, equipment, and character levels in the account will be initialized.<br/>
              3. Stop selling DK Mobile: Genesis NFT cards on ITSBLOC NFT Marketplace<br/>
              4. Mythic Transform/Magic Doll: Can be extracted as NFT, and extracted NFT can be transferred to ITSBLOC wallet.<br/>
              5. NFTs stored in the web inventory or ITSBLOC wallet at the end of the season can be used in the next season<br/><br/><br/><br/>



              ◆ DK Mobile : Genesis 시즌 3 종료 안내<br/><br/>

              1. 종료 일시 : 2023년 8월 30일(수) 14:00 (UTC 05:00)<br/>
              2. 계정 초기화 : 시즌 종료 시점부터 계정 내 캐릭터와 변신, 인형, 장비, 캐릭터 레벨 등 모든 데이터의 초기화<br/>
              3. ITSBLOC NFT 마켓플레이스에서 DK Mobile : Genesis NFT 카드 판매 중지<br/>
              4. 신화 등급의 변신/마법인형은 추출하여 NFT로 변환할 수 있으며, 추출된 NFT는 ITSBLOC 월렛으로 전송 가능<br/>
              5. 시즌 종료 시점에 웹 인벤토리 또는 ITSBLOC 월렛에 보관 중인 NFT는 추후 다음 시즌에서 사용 가능
            </Modal>
          )}
      </div>
    }
    <MainVisual />
    <VideoSection />
    <Gameverse />
    <JoinUs />
    <Community />
    <Together />
    <Question />
    <SendEmail />
    </>
  )
}

export default Main;