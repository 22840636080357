import axios from 'axios';

const Axios = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  timeout: 5000,
  withCredentials: false,
  headers: {
    'X-VALABLE-VERSION': '1',
  },
});

Axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error),
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default Axios;
