import Logo from "assets/img/main-logo.svg"
import Up from "assets/icon/up.svg"
import Sns1 from "assets/icon/circle-sns1.svg"
import Sns2 from "assets/icon/circle-sns2.svg"
import Sns3 from "assets/icon/circle-sns3.svg"
import Sns4 from "assets/icon/circle-sns4.svg"
export const Footer = () => {

  return <footer>
    <ul className="inner">
      <li>
        <div className="mb31">
          <a href="/">
            <img src={Logo} alt={"logo"} />
          </a>
        </div>

        <div className="follow-text mb12">
          <p>
            Follow us
          </p>
        </div>

        <ul className="sns-list mb40">
          <li>
            <a href="https://medium.com/@itsblocofficial" target="_blank">
              <img src={Sns1} />
            </a>
          </li>
          <li>
            <a href="https://discord.com/invite/itsblocofficial" target="_blank">
              <img src={Sns2} />
            </a>
          </li>
          <li><a href="https://twitter.com/itsblocofficial" target="_blank">
            <img src={Sns3} /></a></li>
          <li><a href="https://t.me/itsblocofficial" target="_blank">
            <img src={Sns4} /></a></li>
        </ul>
      </li>
      <li>
        <ul className="menu-list">
          <li>Developers Press </li>
          <li>
            <a href="#contact">
              Contact us ↘︎
            </a>
          </li>
          <li>
            <a href="#faq">
              FAQ ↘︎
            </a>
          </li>
        </ul>
      </li>
      <li>
        <ul className="menu-list">
          <li>Community</li>
          <li>Employment ↘︎ </li>
          <li>News ↘︎ </li>
        </ul>
      </li>
      <li>
        <ul className="menu-list">
          <li>Ecosystem</li>
          <li>
            <a href="https://itsbloc-1.gitbook.io/itsbloc/p4fSA0jPBRvCsKEKImtJ/ecosystem/token/itsbloc-token-usditsb" target="_blank">
              ITSB ↘︎
            </a>
          </li>
          <li>Smart Contract ↘︎ </li>
        </ul>
      </li>
    </ul>
    <div className="scroll-up-container">
      <div className="inner">
        <div className="company-container">
          <p className="company-name mr24">© 2022 ITSBLOC PTE. LTD</p>
        </div>
      </div>
    </div>
  </footer>
}
