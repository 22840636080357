import { useState } from "react"
import Axios from "api";
import { Loading } from "./Loading";
import ScrollUp from "assets/img/scroll-up.svg";

const email_check = (email: string) => {
  const regex = /([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  return (email !== '' && email != 'undefined' && regex.test(email));
}

const name_check = (name: string) => {
  const regex = /[0-9]/
  return (name !== '' && name != 'undefined' && !regex.test(name));
}

export const SendEmail = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [message, setMassage] = useState("")
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const send = async () => {
    if (!email_check(email)) {
      alert("올바른 이메일 형태가 아닙니다.")
      return
    }

    if (!name_check(name)) {
      alert("올바른 이름 형태가 아닙니다. (한글 및 영문만 가능합니다.)")
      return
    }

    if (message.length < 10) {
      alert("10자 이상에 내용을 입력해 주세요.")
      return
    }

    setLoading(true)
    Axios.post("/api/community/contact", {
      email, name, content: message
    }).then(_ => {
      setComplete(true)
      alert("문의가 정상적으로 등록되었습니다.")
    }).catch(e => {
      alert(e.response.data.message[0])
    }).finally(() => {
      setLoading(false)
    })
  }

  return <section className="send-email-container" id="contact">

    <div className="inner">
      <h3 className="has-ani mb24">
        Get in touch with us.
      </h3>

      <h4 className="has-ani mb64">
        Community Chat Ask common questions and chat with the global community on Telegram.
      </h4>

      <div className="email">
        {loading && <Loading />}

        <div className="email-input-wrap mb12">
          <div className="input-container mr12">
            <input
              placeholder="We’ll reply with this address."
              disabled={complete}
              onChange={(e) => {
                setEmail(e.target.value)
              }} />
            <p className="input-title">Mail</p>
          </div>

          <div className="input-container">
            <input placeholder="What is your name?"
              disabled={complete}
              onChange={(e) => {
                setName(e.target.value)
              }} />
            <p className="input-title">Name</p>
          </div>
        </div>

        <div className="">
          <div className={`input-container textarea ${complete ? "complete" : ""}`}>
            <textarea placeholder="Bla. Whatever you want to text us."
              disabled={complete}
              onChange={(e) => {
                setMassage(e.target.value)
              }}
            />
            <p className="input-title textare">Message</p>
          </div>

          <div className="send-button-container">
            {complete ?
              <div className="send-button complete">
                <p className="send-button-tex">
                  Send Complete!
                </p>
              </div>
              : <div className="send-button" onClick={send}>
                <p className="send-button-tex">
                  Send Message
                </p>
              </div>}
          </div>
        </div>
      </div>

      <div className="scroll-up" onClick={() => window.scrollTo(0, 0)}>
        <img src={ScrollUp} />
      </div>
    </div>
  </section>
}
