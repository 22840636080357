import { ReactComponent as Sns1 } from "assets/icon/sns1.svg"
import { ReactComponent as Sns2 } from "assets/icon/sns2.svg"
import { ReactComponent as Sns3 } from "assets/icon/sns3.svg"
import { ReactComponent as Sns4 } from "assets/icon/sns4.svg"
import { ReactComponent as Sns1b } from "assets/icon/sns1-b.svg"
import { ReactComponent as Sns2b } from "assets/icon/sns2-b.svg"
import { ReactComponent as Sns3b } from "assets/icon/sns3-b.svg"
import { ReactComponent as Sns4b } from "assets/icon/sns4-b.svg"
import { BannerType } from "hook/useBanner"

export const BannerItem = ({ item }: { item: BannerType }) => {

  return <div className="community-box" style={{ backgroundColor: item.backgroundColor || "#6922ff" }}>
    <h3 className="box-title tl mb32" style={{ color: item.fontColor }}>
      {item.title}
    </h3>

    <h4 className="box-desc tl mb62" style={{ color: item.fontColor }}>
      {item.content}
    </h4>

    <div className="bottom-button">
      <div className="button-container app-down only-black">
        <a href={item.link} target={"_blank"}>
          <p className="button-text">
            {item.linkBtnText}
          </p>
        </a>
      </div>

      {item.mediumLink && <div className="in-communty-icon ml16">
        <p className="mr6" style={{ color: item.fontColor }}>in</p>
        <a href={item.mediumLink} target={"_blank"}>
          <li>
            {item.fontColor === "#ffffff" ? <Sns1 /> : <Sns1b />}
          </li>
        </a>
      </div>}

      {item.telegramLink && <div className="in-communty-icon ml16">
        <p className="mr6" style={{ color: item.fontColor }}>in</p>
        <a href={item.telegramLink} target={"_blank"}>
          <li>
            {item.fontColor === "#ffffff" ? <Sns4 /> : <Sns4b />}
          </li>
        </a>
      </div>}

      {item.tweetLink && <div className="in-communty-icon ml16">
        <p className="mr6" style={{ color: item.fontColor }}>in</p>
        <a href={item.tweetLink} target={"_blank"}>
          <li>
            {item.fontColor === "#ffffff" ? <Sns3 /> : <Sns3b />}
          </li>
        </a>
      </div>}

      {item.discordLink && <div className="in-communty-icon ml16">
        <p className="mr6" style={{ color: item.fontColor }}>in</p>
        <a href={item.discordLink} target={"_blank"}>
          <li>
            {item.fontColor === "#ffffff" ? <Sns2 /> : <Sns2b />}
          </li>
        </a>
      </div>}

    </div>
  </div>
}
