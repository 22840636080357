import Axios from "api"
import { useEffect, useState } from "react"

export interface BannerType {
  id: number
  title: string,
  content: string,
  backgroundColor: string,
  fontColor: string,
  linkBtnText: string,
  link: string,
  linkedInLink: string,
  discordLink: string,
  mediumLink: string,
  tweetLink: string,
  telegramLink: string,
}

export const useBannerHook = () => {
  const [list, setList] = useState<BannerType[]>([])

  useEffect(() => {
    try {
      Axios.get("/api/community/banner").then(res => {
        setList(res.data.items)
      })
    } catch (e) {
      setList([])
    }
  }, [])

  return { list }
}
