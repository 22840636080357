import Company from "assets/icon/company.svg"
import Company1 from "assets/icon/company1.svg"
import Company2 from "assets/icon/company2.svg"
import Company3 from "assets/icon/company3.svg"
import Company4 from "assets/icon/company4.svg"
import Company5 from "assets/icon/company5.svg"
import Company6 from "assets/icon/company6.svg"
import Company7 from "assets/icon/company7.svg"
import Company8 from "assets/icon/company8.svg"
import Company9 from "assets/icon/company9.svg"
import Company10 from "assets/icon/company10.svg"
import Company11 from "assets/icon/company11.svg"
import Company13 from "assets/icon/company13.svg"
import Company14 from "assets/icon/company14.svg"
import Company15 from "assets/icon/company15.svg"
import Company16 from "assets/icon/company16.svg"
import Company17 from "assets/icon/company17.svg"
import Company18 from "assets/icon/company18.svg"
import Company19 from "assets/icon/company19.svg"
import Company20 from "assets/icon/company20.svg"
import Company12 from "assets/icon/company120.svg"
const arr = new Array(21).fill(1);
export const Together = () => {
  return <section className="togather-container" id="partners">
    <h3 className="has-ani mb24">
      Together,<br />
      We are strong.
    </h3>

    <h4 className="has-ani mb64">
      We're going to create a new Play to Earn Universe with these powerful brands.
    </h4>

    <div className="company-container">
      <div className="icon-container"><img src={Company} alt="company" /></div>
      <div className="icon-container"><img src={Company1} alt="company" /></div>
      <div className="icon-container"><img src={Company2} alt="company" /></div>
      <div className="icon-container"><img src={Company3} alt="company" /></div>
      <div className="icon-container"><img src={Company4} alt="company" /></div>
      <div className="icon-container"><img src={Company5} alt="company" /></div>
      <div className="icon-container"><img src={Company6} alt="company" /></div>
      <div className="icon-container"><img src={Company7} alt="company" /></div>
      <div className="icon-container"><img src={Company8} alt="company" /></div>
      <div className="icon-container"><img src={Company9} alt="company" /></div>
      <div className="icon-container"><img src={Company10} alt="company" /></div>
      <div className="icon-container"><img src={Company11} alt="company" /></div>
      <div className="icon-container"><img src={Company12} alt="company" /></div>
      <div className="icon-container"><img src={Company13} alt="company" /></div>
      <div className="icon-container"><img src={Company14} alt="company" /></div>
      <div className="icon-container"><img src={Company15} alt="company" /></div>
      <div className="icon-container"><img src={Company16} alt="company" /></div>
      <div className="icon-container"><img src={Company17} alt="company" /></div>
      <div className="icon-container"><img src={Company18} alt="company" /></div>
      <div className="icon-container"><img src={Company19} alt="company" /></div>
      <div className="icon-container"><img src={Company20} alt="company" /></div>
    </div>
  </section>
}
