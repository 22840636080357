import Logo from "assets/img/main-logo.svg"
import ArrowDown from "assets/icon/arr-down.svg"
import { MHeader } from "./MHeader"
import { useEffect, useState } from "react"
import ArrowDownP from "assets/icon/arr-down-p.svg"

export const Header = () => {
  const [buttonActive, setButtonActive] = useState(false)
  const [innerJoin, setInnerJoin] = useState(false)

  useEffect(() => {
    const scrollEvent = window.addEventListener("scroll", () => {
      const joinUsTop = document.querySelector(".join-us-container")?.getBoundingClientRect().top || 0
      const joinUsBottom = document.querySelector(".join-us-container")?.getBoundingClientRect().bottom || 0
      if (joinUsTop < 50 && joinUsBottom > 0) {
        setInnerJoin(true)
      } else {
        setInnerJoin(false)
      }
    })

    return scrollEvent
  }, [])
  return <header className={innerJoin ? "inner-jon" : ""}>
    <div className="inner web">
      <h1>
        <a href="/">
          <img src={Logo} alt={"logo"} />
        </a>
      </h1>

      <ul className="menu">
      <li>
          <a href="https://nft.itsbloc.com" target="_blank">
            <p>nft store</p>
          </a>
        </li>
        <li>
          <a href="https://itsbloc-1.gitbook.io/itsbloc/p4fSA0jPBRvCsKEKImtJ/" target="_blank">
            <p>white paper</p>
          </a>
        </li>
        <li>
          <a href="#community">
            <p>Community</p>
          </a>
        </li>
        <li>
          <a href="#partners">
            <p>Partners</p>
          </a>
        </li>
        <li>
          <a href="#faq">
            <p>FAQ</p>
          </a>
        </li>
        <li>
          <a href="#news">
            <p>News & Notice</p>
          </a>
        </li>
        <li className="no-hover">
          <div className={`button-container type-header ${buttonActive ? "active" : ""}`}>
            <div className="d-flex x-center" onClick={() => setButtonActive(!buttonActive)}>
              <p className="button-text mr7" >
                App Download
              </p>

              {innerJoin ? <img src={ArrowDownP} alt="arr-down" className={buttonActive ? "down-arr" : ""} /> : <img src={ArrowDown} alt="arr-down" className={buttonActive ? "down-arr" : ""} />}

            </div>


            <ul className={`list-download ${buttonActive ? "active" : ""}`}>
              <li>
                <a href="https://play.google.com/store/apps/details?id=io.itsbloc.wallet&hl=ko" target="_blank">Android</a>
              </li>
              <li>
                <a href="https://apps.apple.com/kr/app/itsbloc/id1632866346" target="_blank">iOS</a>
              </li>
            </ul>
          </div>

        </li>
      </ul>
    </div>
    <MHeader />
  </header>
}
