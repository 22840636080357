export const VideoSection = () => {
  return <section className="video-container" id="videoSection">
    <div className="inner">
      <h3 className="join-text pb64 tl">Join the Fast-Growing<br />
        Token Economy and Build<br />
        a stable foundation.</h3>
    </div>

    <div className="paper-back">
      <p>
        ITSBLOC GAMES<br />
        ON THE BLOCKCHAIN
      </p>

      <div className="go-paper">
        <a href="https://itsbloc-1.gitbook.io/itsbloc/p4fSA0jPBRvCsKEKImtJ" target="_blank">
          White Paper 1.0.0
        </a>
      </div>
    </div>
  </section>
}
