import { PropsWithChildren, useState } from "react";
import 'assets/scss/modal.scss'
import Close from "assets/img/close.png"
import Season_close from "assets/img/season_close.jpeg"

interface ModalDefaultType {
  onClickToggleModal: () => void;
}

function Modal({
  onClickToggleModal,
  children,
}: PropsWithChildren<ModalDefaultType>) {
  
  return (
    <div className="modal-container">
      <div className="dialog-box">
        <h2>Notice</h2>
        <div className="close-btn"
            onClick={e => {
              e.preventDefault();

              if (onClickToggleModal) {
                onClickToggleModal();
              }
            }}
          >
            <img src={Close} alt="" />
        </div>
        <img src={Season_close} alt="" /><br/><br/>
        {children}
      </div>
      <div className="backdrop"
        onClick={e => {
          e.preventDefault();

          if (onClickToggleModal) {
            onClickToggleModal();
          }
        }}
      />
    </div>
  );
}

export default Modal;
