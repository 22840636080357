import ArrowDown from "assets/icon/arr-down.svg"
import ArrowDownP from "assets/icon/arr-down-p.svg"
import Logo from "assets/icon/logo-m.png"
import { useState } from "react"
import Close from "assets/icon/close.svg"

export const MHeader = () => {
  const [buttonActive, setButtonActive] = useState(false)
  const [menuActive, setMenuActive] = useState(false)

  const goScroll = (id: string) => {
    document.body.style.overflow = "auto"
    setTimeout(() => {
      document.getElementById(id)?.scrollIntoView();
      setMenuActive(false)
    }, 0)
  }

  const open = () => {
    setMenuActive(true)
    document.body.style.overflow = "hidden"
  }

  const close = () => {
    window.scrollTo(0, 0)
    setMenuActive(false)
    document.body.style.overflow = "auto"
  }

  return <><div className="mobile-header">
    <div onClick={() => {
      document.body.style.overflow = "auto"
      setTimeout(() => {
        window.scrollTo(0, 0)
        setMenuActive(false)
      }, 0)
    }}>
      <img src={Logo} alt="logo" />
    </div>

    <div className="d-flex y-center rel">
      <div className={`button-container type-header ${buttonActive ? "active" : ""}`}>
        <div className="d-flex x-center" onClick={() => setButtonActive(!buttonActive)}>
          <p className="button-text mr7" >
            App Download
          </p>

          <img src={ArrowDownP} alt="arr-down" className={buttonActive ? "down-arr" : ""} />
        </div>


        <ul className={`list-download ${buttonActive ? "active" : ""}`}>
          <li>
            <a href="https://play.google.com/store/apps/details?id=io.itsbloc.wallet&hl=ko" target="_blank">Android</a>
          </li>
          <li>
            <a href="https://apps.apple.com/kr/app/itsbloc/id1632866346" target="_blank">iOS</a>
          </li>
        </ul>
      </div>

      {/* <div className="" onClick={open}>
        <img src={Hamb} />
      </div> */}
    </div>
  </div>

    <div className={`fixed-menu ${menuActive ? "active" : ""}`}>
      <div className="top-menu">
        <div onClick={close}>
          <img src={Logo} alt="logo" />
        </div>
        <img src={Close} alt="Close" onClick={() => {
          document.body.style.overflow = "auto"
          setMenuActive(false)
        }} />
      </div>

      <div className="middle">
        <p className="menu-title">Menu</p>
        <ul>
          <li>
            <a href="https://itsbloc-1.gitbook.io/itsbloc/p4fSA0jPBRvCsKEKImtJ/" target="_blank">
              <p>White Paper ↘︎ </p>
            </a>
          </li>
          <li onClick={() => {
            goScroll("community")
          }}>
            <p>Community ↘︎ </p>
          </li>
          <li onClick={() => {
            goScroll("partners")
          }}>
            <p>Partners ↘︎ </p>
          </li>
          <li onClick={() => {
            goScroll("faq")
          }}>
            <p>FAQ ↘︎ </p>
          </li>
          <li className="rel">
            <div className={`button-container type-header fix-button mr16 ${buttonActive ? "active" : ""}`}>
              <div className="d-flex x-center">
                <p className="button-text mr7" onClick={() => setButtonActive(!buttonActive)}>
                  App Download
                </p>

                <img src={ArrowDown} alt="arr-down" />
              </div>

              {buttonActive && <ul className="list-download">
                <li>
                  <a href="https://play.google.com/store/apps/details?id=io.itsbloc.wallet&hl=ko" target="_blank">Android</a>
                </li>
                <li>
                  <a href="https://apps.apple.com/kr/app/itsbloc/id1632866346" target="_blank">iOS</a>
                </li>
              </ul>}
            </div>

          </li>
        </ul>
      </div>

      <div className="bottom">
        <p>
          © 2022 ITSBLOC PTE. LTD
        </p>
      </div>
    </div>
  </>
}
