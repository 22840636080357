import 'assets/scss/service.scss'

const Privacy = () => {
  return (
    <section className="privacy service-box">
      <pre>
        <p>
          (주)이츠블록(이하 &ldquo;회사&rdquo;라 함)는 개인정보 보호법 제30조에 따라 정보주체(서비스 이용자)의 개인 정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 다음과 같이 개인 정보 처리 지침을 수립․공개합니다. 
          본 개인정보처리방침은 회사가 제공하는 &ldquo;이츠블록&rdquo; 및 &ldquo;이츠블록월렛&rdquo; (이하 &ldquo;서비스&rdquo;라 함)에 동일하게 적용되며 다음과 같은 내용을 담고 있습니다. 
        </p>
        
        <ol>
          <li>
            <p>개인 정보 수집 회사는 회원 가입 시 또는 서비스 이용 과정에서 최소한의 개인 정보를 수집하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다. </p>
            <ol>
              <li>회원가입: (필수) 이메일, 이름, 휴대폰번호 </li>
              <li>병원찾기:사용자 위치(위도, 경도) </li>
              <li>이벤트 신청: 이름, 휴대폰 번호, 기타 추가 질문의 대한 답변 </li>
              <li>제휴문의 </li>
            </ol>
          </li>
          <li>
            <p>쿠키 서비스 이용 과정에서 서비스이용기록, 접속로그, 쿠키,접속 IP, 결제 기록, 불량이용 기록이 생성되어 수집될 수 있습니다.</p>
            <ol>
              <li>쿠키 사용 목적 &ndash; 회원과 비회원의 접속 빈도나 방문 시간 등을 분석 &ndash; 이용자의 취향과 관심분야를 파악 및 자취 추적 &ndash; 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공 </li>
              <li>쿠키의 설치/운영 및 거부 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 
                  따라서, 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 
                  다만, 쿠키 설치를 거부할 경우 웹 사용(로그인 등의 일부 서비스)에 제한이 있을 수 있습니다. </li>
            </ol>
          </li>
          <li>
            <p>개인정보 보유기간 회사는 정보주체로부터 개인정보를 수집할 때 아래와 같은 기간동안 개인정보를 보유하고 있습니다. </p>
            <ol>
              <li>회사 내부 방침에 의한 정보보유 사유 ① 부정이용기록으로 인한 부정이용방지 : 1년 </li>
              <li>관련법령에 의한 정보보유 사유 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 서비스 이용자의 개인 정보를 보관합니다. 
                이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다. 
                  <li>웹사이트 방문기록 &ndash; 통신비밀보호법 : 3개월 </li>
                  <li>
                    본인확인에 관한 기록 &ndash; 정보통신망 이용촉진 및 정보보호 등에 관한 법률 : 6개월 회사는 유지가 합리적으로 필요할 경우 서비스 이용자의 개인 정보를 보관하는 경우가 있습니다. 
                  &bull;적용되는 법률, 규제 또는 법률상의 의무를 준수하기 위하여 &bull;제공하던 고객 지원 서비스를 완결하기 위하여 &bull;이용자간 혹은 기타의 분쟁을 조정하기 위해 &bull;인가되지 않은 부정한 서비스 이용 또는 남용을 예방하기 위하여 
                  </li>
              </li>
            </ol>
          </li>
          <li>
            <p>정보주체와 법정대리인의 권리․의무 및 행사방법 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.</p>
            <ol>
              <li>개인정보 열람요구 </li>
              <li>오류 등이 있을 경우 정정 요구 </li>
              <li>삭제요구 </li>
              <li>처리정지 요구 </li>
            </ol>
          </li>
          <li>
            <p>개인정보의 파기 </p>
            <ol>
              <li>회사는 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 적시에 파기합니다. </li>
              <li>파기 절차 및 방법은 다음과 같습니다. &ndash; 파기 절차 : 정보주체가 서비스 이용 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정 기간 저장된 후 파기되어집니다. 
              별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다. 
              &ndash; 파기 방법 : 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 
              </li>
            </ol>
          </li>
          <li>
            <p>개인정보 보호책임자 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제를 처리하기 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
            </li>
        </ol>
      </pre>
      
      <pre>개인정보보호책임자: 전종선</pre>
    </section>
  )
}

export default Privacy;