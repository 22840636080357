import { ReactComponent as Sns1 } from "assets/icon/sns1.svg"
import { ReactComponent as Sns2 } from "assets/icon/sns2.svg"
import { ReactComponent as Sns3 } from "assets/icon/sns3.svg"
import { ReactComponent as Sns4 } from "assets/icon/sns4.svg"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual } from 'swiper';
import 'swiper/css';
import { BannerItem } from "./BannerItem";
import { useBannerHook } from "hook/useBanner";

const checkWindowSize = () => {
  if (window.innerWidth > 1000) return 4
  if (window.innerWidth > 500) return 2
  return 1
}

export const Community = () => {
  const { list } = useBannerHook()

  return <section className="community-container" id="community">
    <h3 className="has-ani mb24">
      Meet the Global<br />
      Community.
    </h3>

    <h4 className="has-ani mb36 mobile">
      The ITSBLOC Foundation is launching an incentive program focused on accelerating the adoption and growth of a new subnet, the first scaling solution for Web3 Game Dapps.
    </h4>


    <h4 className="has-ani mb36 web">
      Community Chat Ask common questions and chat with the<br /> global community on Telegram.
    </h4>

    <ul className="sns-list mb40">
      <li>
        <a href="https://medium.com/@itsblocofficial" target="_blank">
          <Sns1 />
        </a>
      </li>
      <li>
        <a href="https://discord.com/invite/itsblocofficial" target="_blank">
          <Sns2 />
        </a>
      </li>
      <li><a href="https://twitter.com/itsblocofficial" target="_blank">
        <Sns3 /></a></li>
      <li><a href="https://t.me/itsblocofficial" target="_blank">
        <Sns4 /></a></li>
    </ul>

    <div className="swiper-container">
      <Swiper
        spaceBetween={12}
        slidesPerView={checkWindowSize()}
        className="mySwiper"
        centeredSlides={true}
        modules={[Virtual]}
        virtual
      >
        {list.map((i, index) => <SwiperSlide key={"slideContent" + i.id} virtualIndex={index}>
          <BannerItem item={i} />
        </SwiperSlide>)}
      </Swiper>
    </div>

  </section>
}
