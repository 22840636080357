import Scroll from 'assets/icon/scroll.svg'
import { useEffect, useState } from 'react'
import Logo from "assets/icon/visual-logo.svg"

export const MainVisual = () => {
  const [init, setInit] = useState(false)
  const [visual, setVisual] = useState(0)

  useEffect(() => {
    setInit(true)
    const scrollEvent = window.addEventListener("scroll", (e) => {
      const joinUsTop = document.querySelector(".main-visual")?.getBoundingClientRect().top || 0
      setVisual(joinUsTop)
    })

    return scrollEvent
  }, [])

  return <section className={`main-visual ${init && "on-view--true"}`}>
    <div className="visual-text-container">

      <div className="mb10">
        <img src={Logo} alt="logo" className='logo' />
      </div>

      <p className="visual-text mb52">
        Welcome to the<br />
        Gameverse.
      </p>

      <p className="visual-text-desc mb36">
        The ITSBLOC Foundation is launching an incentive program focused on accelerating the adoption and growth of a new subnet, the first scaling solution for Web3 Game Dapps.
      </p>

      <div className="scroll-container">
        <div className="mb8">
          <a href="#videoSection">
            <img src={Scroll} alt="scroll" />
          </a>
        </div>
        <p className="scroll-text">
          Scroll down
        </p>
      </div>
    </div>

    {window.innerWidth > 1500 ?
      <div className="main-back" style={{ backgroundPosition: `left top ${visual}px` }}>
        <div className="dim" style={{ position: "absolute" }}></div>
      </div>
      : <div className="main-back" ></div>}


    <div className="dim" ></div>
  </section>
}
