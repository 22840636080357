import { Community } from 'components/Community'
import { Footer } from 'components/Footer'
import { Gameverse } from 'components/Gameverse'
import { Header } from 'components/Header'
import { JoinUs } from 'components/JoinUs'
import { MainVisual } from 'components/MainVisual'
import { Question } from 'components/Question'
import { SendEmail } from 'components/SendEmail'
import { Together } from 'components/Together'
import { VideoSection } from 'components/VideoSection'
import { intersectionInit } from 'hook/useIntersectionObsever'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Main from 'routes/main'
import Privacy from 'routes/privacy'
import TermOfService from 'routes/termofservice'

export const App = () => {
  intersectionInit()
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Main/>}></Route>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route path="/termofservice" element={<TermOfService />}></Route>
          <Route path="*" element={<Main/>}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

